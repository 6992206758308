.loader {
  @include size(100%, 7rem);
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &Circular {
    @include position(absolute, 0, 0, 0, 0);
    @include size(100%, 100%);
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    margin: auto;
  }

  &Path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -3.5rem;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -12.4rem;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $primaryColor;
  }
  40% {
    stroke: $secondary;
  }
  66% {
    stroke: $neutralLight;
  }
  80%,
  90% {
    stroke: $primaryColor;
  }
}

.buttonLoaderWrapper {
  @include position(absolute, 0, 0, 0, 0);
}

.btnDownload {
  .loader {
    max-width: 2.6rem;
  }
}

.login {
  &Page {
    align-items: center;

    &Title {
      @include font($font-size: map_get($fontSizes, default), $color: $primaryColor)
    }

    &Content {
      justify-content: center;
    }

    &FormWrapper {
      @include size($min-height: 30rem, $width: 37rem);
      @include position(absolute, 50%);
      transform: translateY(-50%);
      border-radius: $basicBorderRadius;
    }
  }

  &Cta {
    font-size: map_get($fontSizes, xs);
    padding: 1.4rem;
    border-radius: $basicBorderRadius;
  }

  &LoaderWrapper {
    @include position(absolute, 50%, 0, null, 0);
    transform: translateY(-50%);
  }
}

//global config
$enableHelperClassesForColor: true;
$enableGridClasses: true;
$enableSizeHelperClasses: true;
$enableSpacingHelperClasses: true;
$enableHelperClassesForBorder: true;
$enableOpacityHelperClasses: true;

//colors
$defaultColor: #fff;
$defaultTextColor: #000;

$primaryColor: #004850;
$primaryColorDark: #767676;

$darkGrey: #00000012;
$lighterGrey: #e9e9e9;
$neutralDarker: #29738026;
$neutralDark: #2973804C;
$neutral: #5EBDD419;
$neutralLight: #F2F2F2;
$neutralLighter: #2973800D;
$neutralLightest: #71888C;
$primaryColorLightest: #e6fbf9;
$primaryColorLightestTransparent: #0FD7C31A;

$secondary: #297380;
$secondaryColorLight: #0FD7C3;
$secondaryColorDarker: #5EBDD4;
$secondaryColorDarkerTransparent: #5EBDD426;

$customColor1: #cc3262;
$customColor2: #9a2b17;
$customColor3: #5630AA;
$customColor4: #bfbfbf;
$customColor5: #61d8c3;
$customColor6: #6ecc3999;
$customColor7: #ff4c2e;

$warningColor: #e6b32c;

$dangerColor: #B03661;
$dangerColorLight: #B0366180;
$dangerColorLightHue: #EEB0B7;
$dangerColorLighter: #fbf1f4;

$highlightColor: #efb4b442;

$themeColors: (
  primary: $primaryColor,
  primaryLight: $secondaryColorLight,
  primaryDark: $primaryColorDark,
  lighterGrey: $lighterGrey,
  neutral: $neutral,
  neutralLight: $neutralLight,
  neutralLightest: $neutralLightest,
  secondary: $secondary,
  secondaryLight: $secondaryColorLight,
  secondaryDarker: $secondaryColorDarker,
  warning: $warningColor,
  danger: $dangerColor,
  default: $defaultColor,
  dangerLight: $dangerColorLighter
);

//font
$primaryFont: "Roboto", "Arial", sans-serif;

//body
$bodyTextColor: #333;
$bodyFontSize: (
        style: font-size,
        default: 1.4rem,
        md: 1.6rem
);
$bodyFontFamily: $primaryFont;

/// Font weights map
$fontWeights: (
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        semi-bold: 600,
        bold: 700,
) !default;

$fontSizes: (
        default: 1.6rem,
        xxs: 1.2rem,
        customXs: 1.3rem,
        xs: 1.4rem,
        customSm: 1.8rem,
        sm: 1.9rem,
        md: 2rem,
        lg: 3rem,
        xlg: 4rem,
);

$spacing: (
        "0": 0,
        "01": .1rem,
        "02": .2rem,
        "05": .5rem,
        "1": 1rem,
        "15": 1.5rem,
        "2": 2rem,
        "25": 2.5rem,
        "3": 3rem,
        "35": 3.5rem,
        "4": 4rem,
        "45": 4.5rem,
        "5": 5rem,
        "6": 6rem,
        "75": 7.5rem,
        "10": 10rem,
        "Auto": auto
);

$sizes: (
        "Full": 100%,
        "FullVh": 100vh,
        "Auto": auto
);

$intensity: (
        "0": 0,
        "10": .1,
        "20": .2,
        "30": .3,
        "40": .4,
        "50": .5,
        "60": .6,
        "70": .7,
        "80": .8,
        "90": .9,
        "100": 1,
);

$breakpoints-min: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

//grid config
$gridGutter: 30px;
$gridColumns: 12;

$containerMaxWidth: (
        style: max-width,
        default: 100%,
        sm: 540px,
        md: 720px,
        lg: 920px,
        xl: 1140px
);

//Buttons
$buttonBasePadding: (
        style: padding,
        default: 1rem 1.5rem,
);
$buttonBaseFontSize: 1.6rem;
$buttonPrimaryBackground: $secondaryColorLight;
$buttonPrimaryColor: $neutralLight;
$buttonPrimaryBackgroundHover: $primaryColor;
$buttonPrimaryColorHover: $secondaryColorLight;
$buttonOutlineColor: $secondary;
$buttonOutlineBackground: transparent;

//navbar
$enableNavbarBoxShadow: true;
$navbarBoxShadow: 0 .2rem 1.2rem 0 rgba(0, 0, 0, .1);
$navbarBackground: $defaultColor;

$navbarLinkColor: $defaultColor;
$navbarLinkColorHover: $primaryColor;
$navbarLinkColorActive: $primaryColor;

$navbarItemGutter: 3rem;

$navbarBrandSize: (
        style: max-width,
        default: 11rem,
        lg: 15rem
);

$toggleSize: 2.8rem;
$toggleSizeOpened: 2rem;
$toggleMargin: 1rem;
$toggleLine: .2rem;
$toggleColor: $defaultColor;
$toggleColorOpened: $defaultColor;

//inputs
$inputFontFamily: $primaryFont;
$inputFontWeight: map-get($fontWeights, regular);
$inputFontSize: 1.6rem;
$inputColor: $primaryColor;

$inputBackground: $defaultColor;
$inputBorder: .1rem solid $neutralDark;
$inputBorderRadius: .5rem;

$inputPadding: (
        style: padding,
        default: 1.4rem 1.3rem,
);
$enableInputSize: true;
$inputWidth: 100%;
$inputHeight: 3rem;

$inputLabelFontFamily: $primaryFont;
$inputLabelLineHeight: 1;
$inputLabelFontSize: 1.6rem;
$inputLabelColor: $primaryColor;
$inputLabelFontWeight: map-get($fontWeights, regular);
$inputLabelMargin: 0 0 1rem 0;
$inputBoxShadow: 0 .2rem 1.2rem 0 rgba(0, 0, 0, .3);

$basicBorderRadius: .5rem;
$buttonBorderRadius: .2rem;

.pageTitle {
  @include font(
    $font-size: 2.6rem,
    $font-weight: map-get($fontWeights, bold),
    $color: $secondary,
  );

  &Wrapper {
    .btnOutline {
      text-transform: initial;
    }
  }
}

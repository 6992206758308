.error {
  &Message {
    color: $dangerColor;
  }

  &Highlight {
    border-color: $dangerColor;
    outline: none;
  }
}

.warning {
  &Message {
    color: $warningColor;
  }
}

.alert {
  padding: 1rem;
  margin-bottom: floor((14px * 1.428571429));
  border: 1px solid transparent;
  border-radius: 4px;

  &Danger {
    background-color: darken(adjust-hue(#f6ecec, -10), 5%);
    border-color: #a94442;
  }

  &Success {
    background-color: $secondaryColorLight;
    border-color: $secondary;
  }

  &Warning {
    background-color: lighten(
      saturate(adjust-hue($warningColor, 2), 21.19),
      36.47
    );
    border-color: darken(saturate(adjust-hue($warningColor, 1), 15.35), 26.86);
  }
}

.alertNetwork {
  margin-bottom: 0;
}

.alertNetworkLink {
  color: inherit;
}

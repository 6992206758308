$contractsGridLayout: (
  style: grid-template-columns,
  default: 1fr,
  md: 1fr 1fr,
);

.contract {
  &Type {
    &List {
      @include generateResponsiveStyle($contractsGridLayout);
      display: grid;
      grid-gap: 1.5rem 3rem;
    }
  }

  &Form {
    .customTable {
      td, th {
        border: 0.1rem solid $neutralDark;
      }

      tr {
        th:first-child {
          display: none;
        }
      }
    }

    .compeedRules {
      table {
        tr:nth-child(odd) {
          td {
            background-color: $neutralLight;

            &.rule {
              background-color: $defaultColor;
            }
          }
        }
      }
    }

    .brandSection {
      border-bottom: 0.2rem solid $secondaryColorLight;
      margin-bottom: 4rem;

      &NUK {
        border-bottom: none;
      }

      &CICATRIDINE {
        margin-bottom: 3rem;
      }
    }
  }
}

.mixedFormType {
  border-color: $dangerColor;

  &List {
    @include generateResponsiveStyle($contractsGridLayout);
    display: grid;
    grid-gap: 1.5rem 3rem;
  }
}

.contractName {
  flex-grow: 1;
}

.contractNo {
  &Status {
    @include border(
      $border-width: 2px,
      $border-style: solid,
      $border-color: $dangerColor,
      $border-radius: 50%
    );
    @include font($color: $dangerColor);
    height: 3.2rem;
    line-height: 3rem;
    min-width: 3.2rem;

    &.active {
      @include font($color: $secondaryColorLight);
      border-color: $secondaryColorLight;
    }
  }
}

.fileToDownloadWrapper {
  object {
    width: 80%;
    height: 100%;
  }
}

.signatureWrapper,
.signatureContentWrapper {
  max-width: 40rem;
}

.contractWarning {
  @include font($font-size: map_get($fontSizes, sm), $font-weight: map_get($fontWeights, bold), $color: $customColor7);
  @include respond-to-min(md) {
    width: 40rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
}
// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($breakpoints-min) {
  @include respond-to-min($breakpoint) {
    $infix: capitalize(breakpoint-infix($breakpoint, $breakpoints-min));

    .flex#{$infix}Row            { flex-direction: row !important; }
    .flex#{$infix}Column         { flex-direction: column !important; }
    .flex#{$infix}RowReverse     { flex-direction: row-reverse !important; }
    .flex#{$infix}ColumnReverse  { flex-direction: column-reverse !important; }

    .flex#{$infix}Wrap           { flex-wrap: wrap !important; }
    .flex#{$infix}Nowrap         { flex-wrap: nowrap !important; }
    .flex#{$infix}WrapReverse    { flex-wrap: wrap-reverse !important; }

    .justifyContent#{$infix}Start   { justify-content: flex-start !important; }
    .justifyContent#{$infix}End     { justify-content: flex-end !important; }
    .justifyContent#{$infix}Center  { justify-content: center !important; }
    .justifyContent#{$infix}Between { justify-content: space-between !important; }
    .justifyContent#{$infix}Around  { justify-content: space-around !important; }

    .alignItems#{$infix}Start    { align-items: flex-start !important; }
    .alignItems#{$infix}End      { align-items: flex-end !important; }
    .alignItems#{$infix}Center   { align-items: center !important; }
    .alignItems#{$infix}Baseline { align-items: baseline !important; }
    .alignItems#{$infix}Stretch  { align-items: stretch !important; }

    .alignContent#{$infix}Start   { align-content: flex-start !important; }
    .alignContent#{$infix}End     { align-content: flex-end !important; }
    .alignContent#{$infix}Center  { align-content: center !important; }
    .alignContent#{$infix}Between { align-content: space-between !important; }
    .alignContent#{$infix}Around  { align-content: space-around !important; }
    .alignContent#{$infix}Stretch { align-content: stretch !important; }

    .alignSelf#{$infix}Auto     { align-self: auto !important; }
    .alignSelf#{$infix}Start    { align-self: flex-start !important; }
    .alignSelf#{$infix}End      { align-self: flex-end !important; }
    .alignSelf#{$infix}Center   { align-self: center !important; }
    .alignSelf#{$infix}Baseline { align-self: baseline !important; }
    .alignSelf#{$infix}Stretch  { align-self: stretch !important; }
  }
}

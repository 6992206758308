.ReactModal {
  opacity: 0 !important;
  transition: all 0.5s ease-in-out;

  &__Overlay {
    background: rgba(41, 115, 128, 0.5) !important;
    z-index: 1001;
  }

  &__Overlay--after-open {
    opacity: 1 !important;
  }

  &__Content {
    opacity: 0;

    &--after-open {
      opacity: 1;
      top: 50% !important;
      bottom: unset !important;
      transform: translateY(-50%);

      div[role='dialog'] {
        height: 100%;
      }

      .contentWrapper {
        flex-direction: column;
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 0.4rem;
        margin-left: 1rem;
      }

      &::-webkit-scrollbar-track {
        background-color: $neutralDarker;
        border-radius: 0.1rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $secondaryColorLight;
        border-radius: 0.1rem;
      }
    }

    &--before-close {
      opacity: 0;
      top: 0.4rem;
      transform: translateY(50%);
    }
  }

  &__Body--open {
    overflow: hidden;
  }
}

.modalFilters {
  &Form {
    .input {
      &Field {
        border: 0.1rem solid $neutralDark;
        padding: 1.2rem 1.3rem;
      }
    }

    .react-datepicker-wrapper {
      .datepickerRight {
        border-radius: 0 0.5rem 0.5rem 0;
        display: block;
      }

      .datepickerLeft {
        border-radius: 0.5rem 0 0 0.5rem;
        display: block;
      }
    }
  }

  &CtaRow {
    @include display-flex($justify-content: space-between);

    .btn {
      padding: 1.4rem;
    }
  }
}

.errorMessageModal {
  &Wrapper {
    .ReactModal {
      &__Content {
        @include font(
          $color: $defaultColor,
          $font-weight: map_get($fontWeights, medium)
        );
        background: $dangerColor !important;
        border: 0.1rem solid $dangerColor !important;
        top: 4.5rem !important;
        left: 50vw !important;
        transform: translateX(-50%);
        width: 50%;
        max-width: 50rem;
        padding: 2.5rem 3rem !important;
      }
    }

    &.backgroundWarning .ReactModal__Content {
      background: $warningColor !important;
      border: 0.1rem solid $warningColor !important;
    }
  }

  &CloseCta {
    @include position(absolute, -2rem, -2.3rem);
    @include size(1rem, 1rem);
    @include on-event() {
      cursor: pointer;
    }
    background: url('#{$modalCloseIcon}') no-repeat center center;
  }
}

.confirmationMessageModal {
  &Wrapper {
    .ReactModal {
      &__Content {
        @include font(
          $color: $defaultColor,
          $font-weight: map_get($fontWeights, medium)
        );
        top: 5.5rem !important;
        left: 50vw !important;
        transform: translateX(-50%);
        width: 50%;
        max-width: 43.4rem;
        padding: 2rem !important;

        .btnOutline {
          border: 0.1rem solid $secondaryColorLight;
        }
      }
    }
  }
}

.withChildren {
  .ReactModal__Content {
    height: 90vh;
  }
}

.infoMessageModal {
  &Wrapper {
    .ReactModal {
      &__Content {
        background: transparent !important;
        border: 0 !important;
      }
    }
  }
}

.modalFiltersForm {
  max-height: 90vh;

  .radioButtonsWrapper {
    column-gap: .35rem;

    @include respond-to-min(lg) {
      column-gap: 2rem;
    }
  }
}

.blurMessageModal {
  .ReactModal {
    &__Overlay {
      background-color: rgba($neutralDark, .80) !important;
    }
  }
}

.blurWrapper {
  filter: blur(4px);
}

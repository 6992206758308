.radioButtons {
  &Wrapper {
    column-gap: 2rem;
  }

  &Field {
    @include position(absolute, 0, 0);
    visibility: hidden;
  }
}

.inputRadio {
  display: none;

  &Wrapper {
    @include display-flex($align-items: center);
    border: $inputBorder;
    color: $primaryColor;
    z-index: 1;
    cursor: pointer;
  }

  &Mask {
    @include position(absolute, 0, 0, 0, 0);
    background: $defaultColor;
    z-index: -1;
    transition: all .3s ease-in-out;
  }

  &:checked ~ {
    .inputRadioMask {
      background: $primaryColor;
    }

    .inputRadioLabel {
      color: $defaultColor;
    }
  }
}

.missionSection {
  h3 {
    border-top: 0.2rem solid $secondaryColorLight;
  }

  .competitionTitle {
    border-bottom: 0.2rem solid $secondaryColorLight;
  }

  .btn {
    &Upload,
    &DeleteImage {
      min-width: 177px;
    }
  }

  &Compeed,
  &Acc45 {
    @include respond-to-min(md) {
      border-right: 0.1rem solid $neutralDark;
    }
  }

  &Cicatridine {
    flex: 0 0 100%;
    max-width: 100%;

    .imagesSectionWrapper {
      @include respond-to-min(md) {
        display: flex;

        > div {
          flex: 1;

          + div {
            padding-left: 2rem;
          }
        }
      }
    }
  }
}

.imagesSection {
  h4 {
    font-size: map_get($fontSizes, xs);

    @include respond-to-min(lg) {
      font-size: map_get($fontSizes, default);
    }
  }

  .imageTitle {
    height: 2*map-get($fontSizes, customSm);
  }
}

.placeholder {
  &Message {
    &Icon {
      @include size(7rem, 7rem);
      display: inline-block;
      background: url("#{$searchIcon}") no-repeat center / contain;
    }

    &Label {
      @include font($font-size: map_get($fontSizes, sm), $font-weight: map_get($fontWeights, bold), $color: $primaryColor, $text-transform: uppercase);
    }
  }
}

.inputCheckbox {
  height: 1.8rem;
  width: 1.8rem;
  position: absolute;
  right: 2rem;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border: 0.1rem solid #2973804C;
  background: transparent;
}

.pagination {
  @include respond-to-min(md) {
    margin-top: 6rem;
  }
  @include display-flex($justify-content: center);

  list-style-type: none;
  margin-top: 2rem;

  li {
    margin: 0 1.3rem;

    &.disabled {
      a {
        cursor: default;
        color: $primaryColor;
      }
    }

    &.active {
      a {
        color: $secondary;
        background: $primaryColorLightestTransparent 0% 0% no-repeat padding-box;
        padding: .5rem;
      }
    }
  }

  a {
    @include font($font-size: 1.6rem, $font-weight: 700, $line-height: 1.2, $color: $secondary, $text-decoration: none);
    @include on-event {
      color: $secondary;
    }
  }
}

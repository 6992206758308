.map {
  &ClientDetail {
    @include size(40.6rem, 100%)
  }

  &ClientList {
    @include size(39.6rem, 100%)
  }
}

.leaflet {
  &-container {
    @include size(39.6rem, 100%)
  }

  &Icon {
    &User {
      background: transparent;
      border: none;
    }
  }
}

.searchSection {
  flex-wrap: wrap;

  .inputSection {
    flex: 0 0 100%;
    margin-top: 10px;
    @media (min-width: 1050px) {
      flex: 0 0 47%;
      margin-right: 12px;
    }
  }

  .btnReset {
    color: #004850;
    font-weight: 500;
    padding: 1.7rem 1rem;
    margin-right: 1rem;
  }

  .btnSection {
    flex: 0 0 100%;
    margin-top: 10px;
    @media (min-width: 1050px) {
      flex: 0 0 51%;
    }
  }
}
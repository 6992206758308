.customTable {
  overflow: hidden;

  &Header {
    border-bottom: .2rem solid $neutralDark;
  }

  &Body {
    tr {
      border-bottom: .1rem solid $neutralDark;
    }
  }

  td {
    padding: 1rem 1.2rem;
  }

  &Quarter {
    tbody {
      tr {
        border: .1rem solid $neutralDark;
      }

      td {
        border-left: .1rem solid $neutralDark;
        border-right: .1rem solid $neutralDark;

        &:first-child {
          border-left: .1rem solid transparent;
        }

        &:last-child {
          border-right:.1rem solid transparent;
        }
      }
    }

    &Header {
      tr {
        &:first-child {
          th {
            padding: 1rem 1.2rem;

            &:first-child {
              min-width: 25rem;
            }

            &:nth-child(2n + 3) {
              position: relative;

              &:before {
                @include position(absolute, 0, 0, 0, 0);
                height: 100vmax;
                content: '';
                display: inline-block;
                background: $neutral;
              }
            }
          }
        }
      }
    }
  }

  &SubHeader {
    th {
      padding: .5rem;
      border-width: 0 .1rem 0 .1rem;
      border-style: solid;
      border-color:  $neutralDark;

      &:last-child {
        border-right: solid transparent;
      }
    }
  }

  &.engagementTable {
    .customTableBody {
      tr {
        td {
          &:first-child {
            max-width: 18rem;
          }
        }
      }
    }
  }
}

.datePicker {
  .react-datepicker-wrapper {
    width: 100%;
  }

  &CustomPadding {
    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }

  &Element {
    .react-datepicker-popper {
      min-width: 240px;
      z-index: 100;
    }
  }
}


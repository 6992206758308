$btnFontSize: (
  style: font-size,
  default: 1.4rem,
  md: $buttonBaseFontSize,
);

.btn {
  @include font(
    $font-weight: map-get($fontWeights, semi-bold),
    $text-transform: capitalize,
    $font-family: $primaryFont
  );
  @include generateResponsiveStyle($buttonBasePadding);
  @include generateResponsiveStyle($btnFontSize);
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: $buttonBorderRadius;

  &Primary {
    @include on-event {
      background: $buttonPrimaryBackgroundHover;
      color: $buttonPrimaryColorHover;
    }
    background: $buttonPrimaryBackground;
    color: $secondary;
  }

  &Outline {
    color: $buttonOutlineColor;
    background: $buttonOutlineBackground;
    border: 0.1rem solid $secondary;
    outline: none;

    &:hover,
    &.active {
      background: $buttonPrimaryColorHover !important;
      border-color: $buttonPrimaryColorHover;
    }
  }

  &Link {
    background: transparent;
    @include font(
      $text-decoration: underline,
      $font-weight: map-get($fontWeights, semi-bold),
      $color: $secondary
    );

    &Disabled {
      color: $primaryColorDark;
      cursor: not-allowed;
    }
  }

  &LinkUnstyled {
    text-decoration: none;
  }

  &Danger {
    background: $dangerColor;
    color: $defaultColor;
  }

  &FilterAppointment {
    &.active {
      color: $defaultColor;
      background: $primaryColor;
    }
  }

  &Upload {
    @include on-event {
      background: $buttonPrimaryBackground url('#{$uploadIcon}') no-repeat
        1.5rem center;
      color: $secondary;
    }

    padding: 1.5rem 1.5rem 1.5rem 4rem;
    background: $buttonPrimaryBackground url('#{$uploadIcon}') no-repeat 1.5rem
      center;
    color: $secondary;
  }

  &CameraUpload {
    @include on-event {
      background: $buttonPrimaryBackground url('#{$cameraIcon}') no-repeat
        1.5rem center;
    }

    background: $buttonPrimaryBackground url('#{$cameraIcon}') no-repeat 1.5rem
      center;
  }

  &PlayCamera {
    @include on-event {
      background: $buttonPrimaryBackground url('#{$playCameraIcon}') no-repeat
        1.5rem center;
    }

    background: $buttonPrimaryBackground url('#{$playCameraIcon}') no-repeat
      1.5rem center;
  }

  &StopCamera {
    @include on-event {
      background: $dangerColor url('#{$stopCameraIcon}') no-repeat 1.5rem center;
      color: $defaultColor;
    }

    background: $dangerColor url('#{$stopCameraIcon}') no-repeat 1.5rem center;
    color: $defaultColor;
  }

  &Delete {
    background: $dangerColor url('#{$trashIconWhite}') no-repeat center;
    height: 49px;
  }

  &Download {
    background: $buttonPrimaryBackground;
  }

  &Icon {
    @include size(4rem, 4rem);
    padding: 0.7rem;

    img {
      width: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  &ContactSection {
    &:focus {
      outline: none;
    }
  }

  &Close {
    font-size: 0;
  }

  &SendEmail {
    background-color: $primaryColor;
  }

  &Disabled {
    @include on-event {
      background-color: $secondaryColorLight;
      color: inherit;
    }

    background-color: $secondaryColorLight;
    color: inherit;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

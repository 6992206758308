@if $enableHelperClassesForColor {
  @each $color, $value in $themeColors {
    .text#{capitalize($color)} {
      color: $value !important;
    }
    .background#{capitalize($color)} {
      background-color: $value !important;
    }

    @each $key, $fade in $intensity {
      .background#{capitalize($color)}Fade#{$key} {
        background-color: rgba(red($value), green($value), blue($value), $fade) !important;
      }
    }
  }
}

.text {
  &Underline {
    text-decoration: underline;

    &None {
      text-decoration: none;
    }
  }

  &Center {
    text-align: center;
  }

  &Right {
    text-align: right;
  }

  &Left {
    text-align: left;
  }
}

.navbar {
  @include position(fixed, 0, null, null, 0);
  @include size(100vh, 7.5rem);
  z-index: 4;
  background: $navbarBackground;
  transition: all .3s ease-in-out;
  @if $enableNavbarBoxShadow {
    box-shadow: $navbarBoxShadow;
  }

  &Nav {
    height: 100vh;
    z-index: 4;
  }

  &Items {
    @include display-flex($flex-direction: column);
  }

  &Item {
    &:last-child {
      display: none;
    }
  }

  &Link {
    @include font(
      $font-size: map-get($fontSizes, default),
      $font-weight: map-get($fontWeights, regular),
      $color: $navbarLinkColor,
      $text-decoration: none
    );
    @include display-flex($justify-content: center);
    transition: .3s ease;
    padding: 2.8rem 0;
    position: relative;

    &:before {
      @include position(absolute, 0, null, 0, 0);
      content: '';
      width: 1rem;
    }

    @include on-event {
      color: $navbarLinkColorHover;
    }

    &Active {
      color: $navbarLinkColorActive;
      background: $primaryColorLightestTransparent;

      &:before {
        background: $secondaryColorLight;
      }
    }
  }

  &Brand {
    display: block;

    img {
      @include generateResponsiveStyle($navbarBrandSize);
      margin: auto;
    }

    &Wrapper {
      display: none;
    }

    &MenuTitle {
      @include font($font-size: 2rem, $color: $defaultColor);
      opacity: .5;
    }
  }

  &ToggleWrapper {
    background: $primaryColor;
    padding: 4rem 0;
  }

  &ToggleButton {
    @include size($toggleSize, $toggleSize);
    $offset: $toggleSize * 0.5;
    position: relative;
    display: block;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }

    &:before,
    &:after {
      @include position(absolute, #{$offset - $toggleLine / 2}, null, null, 0);
      @include size($toggleLine, 100%);
      content: "";
      transform: translate(0, 0);
      background: $toggleColor;
      transition: all .3s ease;
    }

    &:before {
      box-shadow: 0 #{$offset / 1.5} 0 0 $toggleColor;
    }

    &:after {
      box-shadow: 0 #{-$offset / 1.5} 0 0 $toggleColor;
    }

    &.navbarToggleIsActive {
      @include size($toggleSizeOpened, $toggleSizeOpened);
      margin: 0 0 1.3rem auto;

      &:before, &:after {
        box-shadow: none;
        background: $toggleColorOpened;
        top: 1rem;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    &.btnWarning {
      &:before, &:after {
        background: $warningColor;
      }
    }

    &.btnSuccess {
      &:before, &:after {
        background: $primaryColor;
      }
    }
  }

  &Opened {
    width: 26rem;
    background: $primaryColor;
    z-index: 9999;

    .navbarLink {
      justify-content: flex-start;
      align-items: center;
      padding: .8rem 0 .8rem 2.8rem;

      &:not(&:last-child) {
        margin-bottom: 2.5rem;
      }

      &Submenu:not(&:last-child),
      &WithNodesOpened {
        margin-bottom: 1rem;
      }

      &WithNodes {
        padding-right: 3.5rem;
        position: relative;

        &:after {
          @include position(absolute, 0, 1.5rem, 0, null);
          background: url('#{$iconArrow}') no-repeat 98% center;
          content: '';
          transform: rotate(180deg);
          width: 1.5rem;
        }

        &Opened:after {
          transform: initial;
        }
      }

      img {
        margin-right: 1.6rem;
      }

      &:before {
        width: 1.5rem;
        background: transparent;
      }

      &Active {
        color: $defaultColor;
        background: transparent;

        .navbarItemLabel {
          text-decoration: underline;
        }

        &:before {
          background: $secondaryColorLight;
        }
      }

      &SubmenuActive {
        color: $secondaryColorLight;
      }

      @include on-event {
        color: $secondaryColorLight;
      }
    }

    .navbarBrandWrapper {
      @include display-flex($flex-direction: column, $justify-content: center);
    }

    .navbarToggleWrapper {
      padding: 1.6rem 1.6rem 0;
      background: transparent;
    }

    .navbarItem {
      &:last-child {
        display: block;

        .navbarLink {
          @include font($font-size: map_get($fontSizes, xs), $color: $dangerColorLightHue);
          margin-bottom: 0;
        }
      }
    }
  }

  &Overlay {
    @include position(fixed, 0, 0, 0, 0);
    background: rgba(0, 0, 0, .2);
    z-index: -1;
  }
}

//Resets for default html
@import "vendors/vendors";

//Variables, mixins, mediaQueries
@import "abstracts/abstracts";

//General style
@import "layout/layout";

//Components style
@import "components/components";

//Pages style
@import "pages/pages";

$calendarHeaderOrientation: (
  style: flex-direction,
  default: column,
  sm: row
);
.calendarWrapper .fc {
  &-theme-standard {
    background: $defaultColor;
    padding: 1.2rem .5rem .5rem .5rem;
  }

  .fc {
    &-toolbar {
      @include generateResponsiveStyle($calendarHeaderOrientation);

      &.fc-header-toolbar {
        margin-bottom: 1rem;
      }
    }

    &-toolbar-title {
      @include font($font-size: map_get($fontSizes, default), $font-family: $primaryFont, $color: $primaryColor, $font-weight: map_get($fontWeights, regular), $text-transform: capitalize);
    }

    &-button-primary {
      @include font($font-family: $primaryFont, $font-weight: map_get($fontWeights, regular), $text-transform: capitalize);
      font-size: map_get($fontSizes, xs);
      color: $primaryColor !important;
      background-color: transparent !important;
      border: none;
      box-shadow: none !important;

      &.fc-button-active {
        background-color: transparent !important;
      }
    }

    &-dayGridMonth-button,
    &-timeGridWeek-button,
    &-timeGridDay-button,
    &-listWeek-button {
      font-weight: map_get($fontWeights, semi-bold);
      opacity: 0.5;
      border-radius: 0;
      padding: 1.2rem 1.7rem;
      box-shadow: $navbarBoxShadow;

      &.fc-button-active {
        opacity: 1;
        border-bottom: .2rem solid $secondaryColorLight !important;
      }
    }

    &-daygrid-event {
      color: $primaryColor;
    }

    &-prev-button,
    &-next-button {
      font-size: 1.8rem;
      padding: 0;
      box-shadow: $navbarBoxShadow;
    }

    &-today-button {
      text-decoration: underline;
      font-weight: map_get($fontWeights, semi-bold) !important;
    }

    &-daygrid-day-number {
      font-size: 1.2rem;
    }

    &-day-today {
      background-color: $primaryColorLightestTransparent !important;
    }

    &-popover {
      background-color: $primaryColorLightest !important;
    }

    &-timegrid-slot {
      height: 4.8rem;
    }

    &-event {
      font-size: 1.2rem;
      flex-wrap: wrap;

      &-title {
        white-space: initial;
      }
    }
  }

  th {
    font-size: 1.2rem;
    font-weight: map_get($fontWeights, regular);
  }
}


.stickyWrapper {
  &.sticky {
    top: -1px;
  }

  &.isSticky {
    background-color: $defaultColor;
    border-radius: $basicBorderRadius;
    padding: 1.5rem;
    z-index: 1002;

    > div:last-child {
      margin-bottom: 0;
    }
  }
}

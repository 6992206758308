.img-fluid {
  @include size($max-width: 100%, $height: auto);
}

.link {
  @include font(
    $font-size: map-get($fontSizes, default),
    $font-weight: map-get($fontWeights, regular)
  );
  cursor: pointer;
  text-decoration: underline;

  &Basic {
    color: $primaryColor;

    @include on-event() {
      color: $primaryColor;
    }
  }

  &Default {
    color: $secondary;
  }

  &Unstyled {
    color: $secondary;
    text-decoration: none;
  }
}

.d {
  &None {
    display: none;
  }

  &Block {
    display: block;
  }

  &Flex {
    display: flex;
  }

  &Inline {
    display: inline;

    &Flex {
      display: inline-flex;
    }

    &Block {
      display: inline-block;
    }
  }
}

.hidden {
  display: none !important;
}

.hidden-sm-up {
  @include respond-to-min(sm) {
    display: none !important;
  }
}

.hidden-on-mobile {
  @include respond-to-min(sm) {
    display: block !important;
  }
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.text {
  &Uppercase {
    text-transform: uppercase !important;
  }

  &Italic {
    font-style: italic !important;
  }
}

.with {
  &Background {
    &ColorDefault {
      background-color: $defaultColor !important;
    }

    &ColorPrimary {
      background-color: $primaryColorLightestTransparent;
    }

    &ColorSecondary {
      background-color: $secondaryColorDarker !important;
    }

    &ColorThird {
      background-color: $secondary !important;
    }

    &ColorNeutral {
      background-color: $neutralLight !important;
    }

    &Transparent {
      background-color: transparent !important;
    }
  }
}

.hasBoxShadow {
  box-shadow: $navbarBoxShadow;
}

.hasBorderRadius {
  border-radius: $basicBorderRadius !important;
}

.hasBorderBottom {
  border-bottom: 0.1rem solid $neutralDark;
}

.hasBorderTop {
  border-top: 0.5rem solid $secondaryColorDarker;
}

.hasBorderRight {
  @include respond-to-min(md) {
    border-right: 0.1rem solid $neutralDark;
  }
}

.font {
  &Bold {
    font-weight: map_get($fontWeights, bold);
  }

  &Regular {
    font-weight: map_get($fontWeights, regular) !important;
  }

  &Medium {
    font-weight: map_get($fontWeights, medium) !important;
  }

  &MediumSize {
    font-size: medium !important;
  }
}

.textInherit {
  color: inherit;
}

.customParenthesisClient {
  @include font(
    $color: $secondary,
    $font-size: 2rem,
    $font-weight: map-get($fontWeights, medium)
  );
}

.customParenthesisMap {
  color: #0078a8;
}

.overflowAuto {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0.4rem;
    margin-left: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $neutralDarker;
    border-radius: 0.1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 0.1rem;
  }
}

.flexGrow {
  flex-grow: 1;
}

.flex {
  flex: 1;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.notAllowed {
  cursor: not-allowed;
}

.word {
  &BreakAll {
    word-break: break-all;
  }

  &Break {
    word-break: break-word;
  }
}

.vAlign {
  vertical-align: middle;
}

.overlay {
  @include position(absolute, 0, 0, 0, 0);
  z-index: 1;
}

$positionsAbbrev: (
  top: Top,
  right: Right,
  bottom: Bottom,
  left: Left
);

@if $enableHelperClassesForBorder {
  @each $orientationValue, $orientationAbbr in $positionsAbbrev {
    @each $color, $colorValue in $themeColors {
      @each $key, $value in $spacing {
        .border#{capitalize($orientationAbbr)}#{$key}#{capitalize($color)} {
          border-#{$orientationValue}: $value solid $colorValue !important;
        }
      }
    }
  }
}

.tooltip {
  &Tip {
    @include position(absolute, 100%, null, null, 50%);
    border-radius: 0.5rem;
    display: block;
    transform: translateX(-50%);
    width: 270px;
    z-index: 1000;

    &:before {
      @include position(absolute, null, null, 100%, 50%);
      border: 1rem solid transparent;
      border-bottom-color: $defaultColor;
      content: '';
      transform: translate(-0.5rem, 0.4rem);
    }

    ul {
      list-style-type: none;
    }
  }

  &Overlay {
    @include position(fixed, 0, 0, 0, 0);
    background: transparent;
    z-index: 5;
  }
}

.dot {
  border: 0.5rem solid $primaryColor;
  border-radius: 50%;
  display: inline-block;

  &.customColor {
    &Purple {
      border-color: $customColor3;
    }

    &Darkgreen {
      border-color: $primaryColor;
    }

    &Yellow {
      border-color: $warningColor;
    }

    &Red {
      border-color: $customColor1;
    }

    &Darkred {
      border-color: $customColor2;
    }

    &Gray {
      border-color: $customColor4;
    }

    &Blue {
      border-color: $customColor5;
    }

    &Green {
      border-color: $customColor6;
    }
  }
}

@mixin respond-to-min($name, $breakpoints: $breakpoints-min) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min and $min > 0 {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin safariOnly() {
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
    @content;
  }}
}

@mixin isIE11 {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}

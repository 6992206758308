* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  @include font($font-family: $primaryFont, $color: $bodyTextColor);
  @include generateResponsiveStyle($bodyFontSize);
  background: $neutralLight;

  &.noScroll {
    overflow: hidden;
  }
}

#root {
  position: relative;
  height: 100vh;
}

.pageContentWrapper {
  height: 100vh;
  z-index: 2;
}

.unstyledList {
  list-style-type: none;
  padding-left: 0;
}

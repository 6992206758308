.input {
  &Label {
    @include font(
      $font-family: $inputLabelFontFamily,
      $font-size: $inputLabelFontSize,
      $line-height: $inputLabelLineHeight,
      $color: $inputLabelColor,
      $font-weight: $inputLabelFontWeight
    );
    display: block;
    margin: $inputLabelMargin;
  }

  &Field,
  &FieldSearch__input {
    @if $enableInputSize {
      @include size($width: $inputWidth, $height: auto);
    }
    @include font(
      $font-size: $inputFontSize,
      $color: $inputColor,
      $font-family: $inputFontFamily,
      $font-weight: $inputFontWeight
    );
    @include generateResponsiveStyle($inputPadding);
    background: $inputBackground;
    border: $inputBorder;
    border-radius: $inputBorderRadius;
    display: block;

    &Wrapper {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        @include font($color: $primaryColor, $font-style: italic);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        @include font($color: $primaryColor, $font-style: italic);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        @include font($color: $primaryColor, $font-style: italic);
      }

      &::after {
        @include position(absolute, 50%, 1rem);
        @include font($color: $primaryColor, $font-style: italic);
        transform: translateY(-50%);
        content: attr(data-content);
      }
    }

    &.noBorder {
      border: 0;
    }

    &.fontBold {
      @include font($font-weight: map_get($fontWeights, bold));
    }
  }

  &Error {
    .inputField {
      border: 0.1rem solid $dangerColorLight;
    }
  }

  &Date {
    background: url('#{$inputDateIcon}') no-repeat calc(100% - 1rem);
    padding-right: 3.5rem;
  }

  &Select {
    background: url('#{$iconSelectField}') no-repeat 98% center;
    padding-right: 2.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &FieldSearch__input {
    @include on-event() {
      cursor: pointer;
    }
    background: url('#{$iconSelectField}') no-repeat 98% center;
    padding-right: 2.5rem;
  }

  &FieldSearch {
    &.has-focus {
      position: relative;
    }
  }

  &FieldSearch__select {
    @include position(absolute, 100%, 0, null, 0);
    z-index: 1;
  }

  &FieldSearch__options {
    max-height: 30rem;
    overflow: auto;
    list-style-type: none;
    border: $inputBorder;
    background: $defaultColor;

    &::-webkit-scrollbar {
      width: 0.4rem;
      margin-left: 1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: $neutralDarker;
      border-radius: 0.1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary;
      border-radius: 0.1rem;
    }
  }

  &FieldSearch__row {
    border-bottom: $inputBorder;
  }

  &FieldSearch__option {
    @include font(
      $font-family: $primaryFont,
      $font-size: map_get($fontSizes, xs),
      $color: $primaryColor
    );
    @include on-event() {
      cursor: pointer;
    }
    width: 100%;
    text-align: left;
    padding: 1.2rem;
    background: $defaultColor;
    border: none;
  }

  &CommentaryInput {
    @include font(
      $font-family: $primaryFont,
      $font-size: map_get($fontSizes, default),
      $color: $primaryColor,
      $font-style: italic
    );
    padding: 1.5rem 1.3rem;
    border: 0.1rem solid $neutralDark;
  }

  &Group {
    &.noLabel {
      .inputLabel {
        display: none;
      }
    }

    &.noBorder .inputLabel {
      border: 0;
    }

    &.fontBold .inputLabel {
      @include font($font-weight: map_get($fontWeights, bold));
    }

    .selectGroup {
      &__control {
        border: $inputBorder;
        border-radius: $inputBorderRadius;

        &--is-focused,
        &:hover {
          border: $inputBorder;
          box-shadow: none;
        }
      }

      &__menu {
        z-index: 99;
      }

      &__option {
        &--is-selected {
          background-color: $lighterGrey;
          color: $defaultTextColor;
        }
      }

      &__value-container {
        padding: 1rem 1.3rem;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }

  &Button {
    color: #297380;
    background: transparent;
    border: 0.1rem solid #297380 !important;
    font-size: 1.4rem !important;
    outline: none;
  }

  &Checkbox {
    &Wrapper {
      align-items: center;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      .inputLabel {
        margin: 0;
        padding: 1.4rem 4.2rem 1.3rem 1.5rem;
        border: $inputBorder;
        border-radius: $basicBorderRadius;
        word-break: break-word;
        z-index: 1;

        &:hover {
          cursor: pointer;
        }
      }

      &Inline {
        .inputLabel {
          padding: 0 4.2rem 0 0;
          border: none;
        }
      }
    }

    &Control {
      @include size(2rem, 2rem);
      @include position(absolute, null, 2rem);
      display: inline-block;
      transition: all 0.3s ease-in-out;
      border: $inputBorder;
      background: transparent;
    }

    &Field {
      @include size(0, 0);
      visibility: hidden;
      &:checked ~ .inputCheckboxControl {
        border: 0.1rem solid $secondaryColorLight;
        background: transparent url('#{$checkedIcon}') no-repeat center /
          contain;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.customSearchInput,
.selectGroup__menu-portal {
  .selectGroup, .inputSearchField {
    &__control {
      border-color: $neutralDark;

      &:hover {
        border-color: $neutralDark;
      }
    }

    &__value-container {
      padding: 0.9rem;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
    }

    &__loading-indicator {
      color: $secondary;
    }

    &__dropdown-indicator {
      background: url('#{$iconSelectField}') no-repeat center;

      svg {
        fill: transparent;
      }
    }

    &__menu {
      margin: 0;
      z-index: 100;
      border-radius: 0;

      &-list {
        padding: 0;
        max-height: 20rem;
        overflow-y: auto;
        word-break: break-word;

        &::-webkit-scrollbar {
          width: 0.4rem;
          margin-left: 1rem;
        }

        &::-webkit-scrollbar-track {
          background-color: $neutralDarker;
          border-radius: 0.1rem;
        }

        &::-webkit-scrollbar-thumb {
          background: $secondary;
          border-radius: 0.1rem;
        }
      }
    }

    &__option {
      @include font(
        $font-family: $primaryFont,
        $font-size: map_get($fontSizes, xs),
        $color: $primaryColor
      );
      @include on-event() {
        cursor: pointer;
      }
      width: 100%;
      text-align: left;
      padding: 1.2rem;
      background: $defaultColor;
      border-bottom: $inputBorder;

      &:last-child {
        border-bottom: none;
      }
    }

    &__multi-value {
      min-width: auto;
      max-width: 10rem;
    }
  }

  .inputSearchField {
    &__value-container {
      padding: 1rem;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.filtersSection {
  .input {
    &SearchField {
      &__value-container {
        flex-wrap: wrap;
      }
    }
  }
}

.infoLabel {
  @include font(
    $font-family: $inputLabelFontFamily,
    $font-size: 1.3rem,
    $line-height: 1.6rem,
    $color: $inputLabelColor,
    $font-weight: $inputLabelFontWeight
  );
  padding-left: .2rem;
  display: block;
  margin: $inputLabelMargin;
}


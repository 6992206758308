.appointmentPage {
  &DateGroup {
    .datePicker {
      &GroupWrapper {
        align-items: flex-start !important;
      }
    }
  }

  .errorMessage {
    display: block;
    margin-top: .5rem;
  }
}

.viewAppointment {
  .btnIcon, .btnDelete {
    width: 4.9rem;
  }

  .btnIcon {
    height: 4.9rem;
    padding: 1rem;
  }

  .sendEmailSection {
    position: relative;

    .sendEmailWrapper {
      background-color: $defaultColor;
      border-radius: $inputBorderRadius;
      margin-top: 1rem;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 10;

      @include respond-to-min(md) {
        right: 2.5rem;
        transform: translateX(50%);
      }
    }
  }

  .appointmentDataError {
    .sendEmailWrapper {
      left: 0;
      right: unset;

      @include respond-to-min(md) {
        left: initial;
        right: 0;
        transform: unset;
      }
    }
  }

  .sendEmailForm {
    .inputField {
      min-width: 23rem;
    }
  }
}

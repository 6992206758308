.customImageUpload {
  &Wrapper {
    flex-direction: column;
  }

  &ImagePreview {
    width: 17.5rem;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  &BtnWrapper {
    .btnUploadImage {
      background-image: url('#{$uploadIcon}') no-repeat left center;
    }

    .btnDeleteImage {
      background-image: url('#{$trashIconWhite}');
      background-repeat: no-repeat;
      background-position: 1rem center;
      color: $defaultColor;
      padding: 1.5rem 1.5rem 1.5rem 3.4rem;
    }
  }
}

.video {
  @include respond-to-min(lg) {
    max-width: 400px;
  }

  max-width: 100%;
}

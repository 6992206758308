$clientFileSectionGridLayout: (
    style: grid-template-columns,
    default: 1fr,
    md: 1fr 3fr
);

$dataListPadding: (
    style: padding,
    default: 1rem 0 0,
    md: 0 6rem 0 1rem
);

.clientFile {
  &Data {
    &Type {
      @include font($font-size: map_get($fontSizes, md), $font-weight: map_get($fontWeights, bold), $color: $secondaryColorDarker, $text-transform: uppercase)
    }

    &Section {
      @include generateResponsiveStyle($clientFileSectionGridLayout);
      display: grid;
    }

    &List {
      @include generateResponsiveStyle($dataListPadding);

      li {
        display: grid;
        grid-template-columns: 2fr 3fr;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: .1rem solid $neutralDark;
        color: $primaryColor;

        &:last-child {
          border-bottom: none;
        }

        span {
          &:first-of-type {
            font-weight: map_get($fontWeights, semi-bold);
          }
        }
      }
    }
  }

  &CommentaryInput {
    @include font($font-family: $primaryFont, $font-size: map_get($fontSizes, default), $color: $primaryColor, $font-style: italic);
    padding: 1.5rem 1.3rem;
    border: .1rem solid $neutralDark;
  }

  &ButtonsWrapper {
    justify-content: space-between;
  }
}

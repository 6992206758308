.dayActivities {
  &List {
    max-height: 20rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: .4rem;
      margin-left: 1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: $neutralDarker;
      border-radius: .1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondaryColorLight;
      border-radius: .1rem;
    }

    li {
      span {
        &:first-child {
          padding: .6rem .7rem;
          background: $secondaryColorDarkerTransparent;
        }
      }
    }
  }

  &CtaWrapper {
    .btn {
      text-transform: initial;
    }
  }
}

.visitReports {
  background-color: $secondaryColorDarkerTransparent;

  &List {
    li {
      background-color: $neutralLight;
      border-radius: $buttonBorderRadius;
    }
  }
}

.appointmentsReports {
  .active {
    position: relative;

    &:before {
      @include position(absolute, 0, 0, 0, 0);
      height: 100vh;
      content: '';
      display: inline-block;
      background: $neutral;
    }
  }
}
